@import "feather";

footer>p {
    font-size: .9em;
    color: gray;
}

// doc images

.tb {
    position:relative;
    top: 1em;
    text-align: center;
    margin: 1em 0 0 0;
}
.doc-image {
    margin: .5em 0;
}
.doc-image-2x { // setup for 2x res.
    width: 100%;
    max-width: 641px;
}
// Sizing

.fe-lg {
  font-size: 2em;
}

.fe-xlg {
  font-size: 3em;
}

.fe-home-icon {
    font-size: 1em;
    font-weight: bold;
    background-color: rgb(238, 233, 240);
    color:rgb(203, 74, 236);
    border-radius: .8em;
    padding: .25em;
    margin: auto .25em auto 0;
    display: inline-block;
}

// Misc

div.footer-content {
    border-top: 1px solid #eee;
    margin: 8em 0;
    padding: 1em 0;
    font-size: .8em;
    color: #aaa;
}

a.site-title {
    border: none;
}
.nav-list {
    a.nav-external {
        color: #453b6e;
    }
}

.label {
    border-radius: 6px;
}

.hcallout {
    text-transform: uppercase;
    font-weight: 300;
    clear: both;
    font-size: 12px;
    letter-spacing: 0.1em;
    display: block;
    margin: 1.2em 0 0.8em 0;
}

a.pro {
    background-color: #5489EF;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 8px;
    text-decoration: none;
    position: relative;
    top: -.25em;
}

a.platform {
    background-color: #bbbbbb;
    color: white;
    font-size: .75em;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 8px;
    text-decoration: none;
    &.available {
        background-color: #258d25;
    }
}

a.new {
    background-color: #e24545;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 8px;
    text-decoration: none;
    position: relative;
    top: -.25em;
}
a.coming-soon {
    background-color: #8FC55E;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 6px;
    border-radius: 8px;
    text-decoration: none;
    position: relative;
    top: -.25em;
}


div.video {
    width: auto;
    border: 1px solid #ddd;
    background: #eee;
    margin: .5em;
}

.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

div.pro-features {
    >div {
        display: table;
        >div {
            vertical-align: top;
            display: table-cell;
            margin: 1.5em 0;
            padding: .5em;
            &.icon>img {
                width: 80px;
                height: 80px;
                max-width: 80px;
            }
            &.content {
                padding: 1em 1em;
                h2 {
                    margin: 0;
                    padding: 0;
                }
                p {
                    margin: 0;
                    padding: .25em 0;
                }
            }
        }
    }
}

blockquote {
    p:first-child {
        margin-top: 0;
    }
}

.hidden {
    display: none;
}

div.banner {
    font-size: 1.25em;
    text-align: center;
    padding: .5em 1em;
    margin: 0 0 2em 0;
    border-radius: 10px;

    &.gray {
        background-color: #424242;
        color: #ccc;
        border: 2px solid #a3aaad;
    }
    &.yellow {
        background-color: #E0A526;
        color: #343E47;
        border: 2px solid #343E47;
    }
    &.red {
        background-color: #B00910;
        color: #FFFFFF;
        border: 2px solid #ddd;
    }
    &.purple {
        background-color: #8C7E9F;
        color: #FFFFFF;
        border: 2px solid #ddd;
    }
}

blockquote {
    background-color: #F4F5F9;
    padding: 6px 12px;
    margin: 1.5em 1.5em 1.5em .25em;
    border-left: 3px solid $blue-300;
    border-radius: 4px;
    box-shadow: 2px 2px 4px $grey-lt-300;

    >p:last-child {
        margin-bottom: 0px;
    }

    &.warning {
        background-color: #faf2f2;
        border-left: 3px solid maroon;
        color: maroon;
    }
}

.main-content {
    blockquote {
        h4 {
            color: $blue-300;
            font-weight: bold;
            margin: 0.25em 0 0 0;
            padding: 0;
        }
        &.warning {
            >h4 {
                color: maroon;
            }
        }
}
}

// Print-only styles.
@media print {
  .side-bar, .page-header, .footer-links { display: none; }
  .main-content { max-width: auto; margin: 1em; position: static; }
}

